import React from "react";

interface ProgressBarProps {
    progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
    const progressBarWidth = {
        width: `${progress}%`,
    };

    return (
        <div className="progress__bar">
          <div className="progress" style={progressBarWidth}></div>
        </div>
      );
}

export default ProgressBar;