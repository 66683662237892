import React, { useState, useEffect, ChangeEvent } from 'react';

interface DateProps {
    onDateChange: (value: string) => void;
    value: string;
}

const Date: React.FC<DateProps> = ({ onDateChange, value }) => {
    const [date, setDate] = useState('');

    useEffect(() => {
        setDate(value);
    }, [value]);

    const updateDate = (e: ChangeEvent<HTMLInputElement>) => {
        setDate(e.target.value);
        onDateChange(e.target.value);
    };

    return (
        <div className="date__container">
            <input
            className="date__input"
            type="date"
            value={date}
            onChange={updateDate}
            ></input>
        </div>
    );
}

export default Date;