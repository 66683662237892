import React, { useEffect } from 'react';

interface FormFieldOptions {
  id: number;
  optionValue: string;
  optionLabel: string;
}

interface CheckboxProps {
    options: FormFieldOptions[];
    name: string;
    selectedValues: string[];
    onValuesChange: (values: string[]) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ options, selectedValues, onValuesChange }) => {
  const handleCheckboxChange = (value: string) => {
    const updatedValues = selectedValues.includes(value)
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value];

      onValuesChange(updatedValues);
    
};

    return (
        <div className='checkbox__container'>
          {options.map((option) => (
            <label key={option.id} className='checkbox__label'>
              <input
                type="checkbox"
                name="checkbox_option"
                value={option.optionLabel}
                checked={selectedValues.includes(option.optionLabel)}
                className='checkbox__input'
                onChange={() => handleCheckboxChange(option.optionLabel)}
              />
              <span className='checkbox'></span>
              <span className='checkbox_text'>{option.optionLabel}</span>
            </label>
          ))}
        </div>
      );
}

export default Checkbox;