import React, { useEffect } from 'react';

interface FormFieldOptions {
  id: number;
  optionLabel: string;
  optionValue: string;
  sortOrder: number;
}

interface SelectorProps {
    options: FormFieldOptions[];
    selectedValue: string;
    onValueChange: (value: string) => void;
}

const Selector: React.FC<SelectorProps> = ({ options, selectedValue, onValueChange }) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    onValueChange(newValue);
  };

  useEffect(() => {
    if (selectedValue !== 'Please Select' && !options.some((option) => option.optionLabel === selectedValue)) {
      onValueChange(selectedValue);
      //onValueChange(selectedValue);
    }
  }, [options, selectedValue, onValueChange]);

    return (
        <div>
          <select value={selectedValue} onChange={handleSelectChange} className="selector_input">
            <option value="Please Select" disabled>
              Please Select
            </option>
            {options.map((option) => (
              <option key={option.id} value={option.optionLabel} className="selector_options" >
                {option.optionLabel}
              </option>
            ))}
          </select>
        </div>
      );
}

export default Selector;