import React, { useState, useEffect } from 'react';

interface ProgressStageProps {
    currentStage: number;
    totalStages: number;
}

const ProgressStage: React.FC<ProgressStageProps> = ({ currentStage, totalStages }) => {
    const [completedStage, setCompletedStage] = useState<number[]>([]);

    useEffect(() => {
        if(currentStage > completedStage.length) {
            setCompletedStage([...completedStage, currentStage])
        }

    }, [currentStage, completedStage]);

    const renderStages = () => {
        const stages: JSX.Element[] = [];
        for (let i = 0; i < totalStages; i++) {
          const isActive = i === currentStage;
          const isCompleted = i < currentStage || completedStage.includes(i);
          const borderStyle = isCompleted || isActive ? '#333 2px solid' : '2px solid rgb(192, 192, 192)';
          const stageColor = isActive ? '#333' : 'rgb(192, 192, 192)';
          stages.push(
            <div className="li__div" key={i} style={{ border: borderStyle, color: stageColor }}>
              <li>{i + 1}</li>
            </div>
          );
        }
        return stages;
      };

      return (
        <div className="progress__stage">
          <ul>{renderStages()}</ul>
        </div>
      );
}

export default ProgressStage;