import React, { useState, useEffect, ChangeEvent } from 'react';

interface EmailProps {
  autoComplete?: string | undefined;
  onEmailChange: (value: string) => void;
  onEmailValidityChange: (isValid: boolean) => void;
  value: string;
 }

const Email: React.FC<EmailProps> = ({autoComplete, value, onEmailChange, onEmailValidityChange}) => {
    const[localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value])

    const updateEmail = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

        // console.log("Entered email:", newValue);
        
        setLocalValue(newValue);

        const isValidEmail = newValue === '' || /^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/.test(newValue);
  
        if(isValidEmail) {
          setLocalValue(newValue);
          onEmailChange(newValue);
          onEmailValidityChange(true);
        } else {
          console.error("Invalid Email Input");
          onEmailValidityChange(false);
        }
    };

    return (
        <div>
          <input
            autoComplete='email'
            type="email"
            value={localValue}
            onChange={updateEmail}
            className="email__input"
            required
          />
        </div>
      );
}

export default Email;