import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';

interface PreLoaderProps {
    isLoading: boolean;
  }

function PreLoader({ isLoading }: PreLoaderProps) {
    const [loading, setLoading] = useState<boolean | undefined>(undefined);

    // useEffect(() => {
    //     setTimeout(() => setLoading(false), 2000)
    // }, []);

    useEffect(() => {
        // Set loading state based on the isLoading prop
        setLoading(isLoading);
    
        // If isLoading is true, simulate loading delay and set loading to false after 2000ms
        if (isLoading) {
          const timeoutId = setTimeout(() => {
            setLoading(false);
          }, 0);
    
          // Cleanup the timeout when the component unmounts or when isLoading becomes false
          return () => clearTimeout(timeoutId);
        }
      }, [isLoading]);

    // console.log(loading);

    return (
        <>
            {!loading ?(
              <ReactLoading 
                type={"spin"}
                color={"rgb(0,191,255)"}
                height={85}
                width={85}
              />
            ) : (
                <div>
                    Pre Loader not working.
                </div>
            )}
        </>
    );
}

export default PreLoader;