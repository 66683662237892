import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './success.css';
import GreenTick from '../images/green-tick.png';

// Displays a green tick with either a success message from the form or a default success message if the form doesn't have one.
function SuccessComponent() {
    const location = useLocation();
    const { successMessage, redirectUrl, downloadUrl } = location.state || {};
    const defaultMessage = 'Thank you for filling out the form! The team will review the information you provided and contact you as soon as possible.';
    
    useEffect(() => {
        if (redirectUrl) {
          window.open(redirectUrl, '_blank');
        }
      }, [redirectUrl]);

    return (
        <div className="success__background">
            <div className="success__container">
            <img src={GreenTick} alt='Green Confirmation Tick'></img>
                <div className="success__message">
                    {successMessage || defaultMessage}
                </div>
                {downloadUrl && (
                    <div className="downloadUrl__container">
                    <a href={downloadUrl} target='_blank' rel="noopener">Link to File</a>
                 </div>
                )}
            </div>
        </div>
    );
}

export default SuccessComponent;