import React, { useState, useEffect, ChangeEvent } from 'react';

interface PhoneNumberProps {
    onPhoneNumberChange: (value: string) => void;
    value: string;
    title: string;
    autoComplete?: string | undefined;
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({ onPhoneNumberChange, value, title, autoComplete }) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const updateNumber = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setLocalValue(newValue);
      onPhoneNumberChange(newValue);
    };

    return (
        <div>
          <input
            title={title}
            type="number"
            value={localValue}
            onChange={updateNumber}
            className="phone__input"
            autoComplete={autoComplete}
          />
        </div>
      );
}

export default PhoneNumber;