import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useParams, Link, useNavigate } from 'react-router-dom';
import Form from './components/Form';
import { useState, useEffect } from 'react';
import SuccessComponent from './components/SuccessComponent';
import PreLoader from './components/PreLoader';

interface configTypes {
  fields: FieldType[];
  title: string;
  type: string;
  autoComplete?: string | undefined;
  [key: string]: any;
}

interface FieldType {
label: string;
}

// base url + apiKey + uniqueId

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [configData, setConfigData] = useState<configTypes[]>([]);
  const [allData, setAllData] = useState<configTypes[]>([]);

  const apiKey = 'YksiA5h8Sh_y1H-HD4M7b8JeuqAF4_-5SE6zWFrtinw';
  const formId = 'NIK5mTK9nQ';

  // selectors form id: owYzFAFw7I

       return (
         <div>
           <Router>
             <div>
                {/* <Link to={`/${apiKey}/${formId}`}>
                 Go to form
               </Link>     */}
               <Routes>
                 <Route 
                   path="/:apiKey/:formId" 
                   element={
                     <Form 
                       data={allData} 
                       configData={configData} 
                       apiKey={apiKey}
                       formId={formId}
                       setLoading={setLoading}
                     />
                   } 
                 />
                 <Route path="/success" element={<SuccessComponent />} />
                 {/* <Route path="/*" element={<PreLoader isLoading={loading}/>} /> */}
                 
               </Routes>
             </div>
           </Router>
         </div>
       );
}

export default App;