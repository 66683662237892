import React, { FormEvent, useState, useEffect } from 'react';
import ProgressBar from './ProgressBar';
import ProgressStage from './ProgressStage';
import Selector from './Selector';
import Text  from './Text';
import Email from './Email';
import PhoneNumber from './PhoneNumber';
import Checkbox from './Checkbox';
import Address from './Address';
import Radio from './Radio';
import Date from './Date';
import { useNavigate, useParams } from 'react-router-dom';
import AbsoluteLogo from '../images/absolute-logo.png';
import PreLoader from './PreLoader';
import { FormFieldOptions } from '../types';
import { stat } from 'fs';
import { config } from 'process';

interface FormProps {
    configData: configTypes[];
    // successMsg: configTypes[];
    data: configTypes[] | configTypes;
    apiKey: string;
    formId: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface configTypes {
    fields?: FieldType[];
    title: string;
    type: string;
    // formFieldOptions?: FormFieldOptions[];
    autoComplete?: string | undefined;
    [key: string]: any;
}

interface FieldType {
  label: string;
}

export interface TextValues {
  firstName: string;
  surname: string;
  phone: string;
  number: string;
  email: string;
  [key: number]: string;
}

// export interface FormFieldOptions {
//   id: string;
//   optionLabel: string;
//   optionValue: string;
// }

// Main component for the form
function Form({ data }: FormProps) {
  const { apiKey } = useParams();
  const { formId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [configData, setConfigData] = useState<configTypes[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [allData, setAllData] = useState<configTypes[]>([]);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

const apiUrl = `https://ingest-form-api.integratedinterest.com/config/${formId}`;

const fetchFormData = async () => {
  try {
      // setConfigData(staticConfig);
      // setSuccessMessage("Thank you for filling out the form! We will email you the PDF shortly.")
    
      const response = await fetch(apiUrl, {
        headers: {
          'x-api-key': apiKey || '',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch form data: ${response.status} - ${response.statusText}`);
      }

      // console.log('API Response Data:', response);

      const { fields, successMessage, redirectUrl, downloadUrl, ...restData } = await response.json();
      setConfigData(fields);
      setSuccessMessage(successMessage);
      setAllData(restData);
      setRedirectUrl(redirectUrl);
      setDownloadUrl(downloadUrl);
    
    await new Promise(resolve => setTimeout(resolve, 3000));
    setLoading(false);
  } catch (error) {
    console.error("Error retrieving form data:", error);
  }
};

// console.log("data:", allData);

  useEffect(() => {
    fetchFormData(); 
  }, [formId, apiKey]);

  // Variables that calculate the starting width of the progress bar and how much it needs to increment by each time a new stage is reached
  const totalStages = configData.length;
  const increment = 100 / totalStages;
  const initialProgress = 100 / (totalStages * 2);
  
  // variables that store the values of which page the user is on and their progress which is used to update the progress bar
  const [progress, setProgress] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setProgress(initialProgress);
  }, [initialProgress]);

  // Makes currentField a state
  const [currentField, setCurrentField] = useState<configTypes | null>(null);

  useEffect(() => {
    if (configData.length > 0 && currentPage >= 0 && currentPage < configData.length) {
      setCurrentField(configData[currentPage]);
    }
  }, [currentPage, configData]);
  
  const { formFieldType, label, description, isRequired } = currentField || {} as configTypes;
  const fieldOptions = currentField?.formFieldOptions || [];

  // useState variables to save info for each question
  const [dataUpdated, setDataUpdated] = useState(false);
  const [uniqueQuestionId, setUniqueQuestionId] = useState<Record<number, string>>({});
  const [textValues, setTextValues] = useState<TextValues>({} as TextValues);
  const [selectedSelector, setSelectedSelector] = useState<Record<number, string>>({});
  const [radioValues, setRadioValues] = useState<Record<number, string>>({});
  const [checkboxValues, setCheckboxValues] = useState<Record<string, string[]>>({});
  const [dateValue, setDateValue] = useState<string>('');
  const [addressValues, setAddressValues] = useState({
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    postcode: '',
    country: 'Select Country',
  });
  const [isEmailValid, setIsEmailValid] = useState(true);

  //Handler function for address changes
  const handleAddressChange = (updatedAddress: any) => {
    setAddressValues(updatedAddress);
  }

  // Handler function for date changes
  const handleDateChange = (value: string) => {
    setDateValue(value);
  }

  // Handler function for selector changes
  const handleSelectorChange = (fieldType: string, value: string) => {
    setSelectedSelector((prevValues) => ({
      ...prevValues,
      [currentPage]: value,
    }));
    setDataUpdated(false); 
  };

  // Function that gets the selected value from the selector
  const getSelectedValue = (fieldType: string) => {
    return selectedSelector[currentPage] || 'Please Select';
  }

  // Handler function for checkbox changes
  const handleCheckboxChange = (values: string[], questionLabel: string) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [questionLabel]: values,
    }));
  };

  // Handler function for email or phoneNumber changes
  const handleTextChange = (value: string, fieldName: string) => {
    setTextValues((prevValues) => ({
      ...prevValues,
      [currentPage]: value,
      [fieldName]: value,
    }));

    // if (currentField?.formFieldType.type === 'yes_no') {
    //   setRadioValues((prevValues) => ({
    //     ...prevValues,
    //     [currentPage]: value,
    //   }));
  }

  useEffect(() => {
    if (dataUpdated) {
      updateUniqueQuestionId();
      setDataUpdated(false);
    }
  }, [checkboxValues, dataUpdated]);

  const handleEmailChange = (value: string, fieldName: string) => handleTextChange(value, fieldName);
  const handlePhoneNumberChange = (value: string, fieldName: string) => handleTextChange(value, fieldName);

  // funciton that makes the address component required (line4 is not required)
  const getAddressString = (): string => {
    const { line1, line2, line3, line4, postcode, country } = addressValues;

    const isAddressFilled = [line1, line2, line3, postcode].filter(value => value.trim() !== '').length >= 4;
    const isCountrySelected = country !== 'Select Country';

    const line4Entered = line4.trim() !== '' ? `${line4}, ` : '';

    if (isAddressFilled && isCountrySelected) {
      return `${line1}, ${line2}, ${line3}, ${line4Entered}${postcode}, ${country}`;
    }


    return '';
  };

  const updateUniqueQuestionId = () => {
    if (currentField) {
      const currentFieldType = currentField.formFieldType;
      const currentLabel = currentField.label;
  
      switch (currentFieldType?.type) {
        case "selectbox":
        case "salutation":
          setUniqueQuestionId((prevIds) => ({
            ...prevIds,
            [currentLabel]: getSelectedValue(currentFieldType.type),
          }));
          break;
        case "firstname":
        case "surname":
        case "email":
        case "phone":
        case "number":
        case "textarea":
          setUniqueQuestionId((prevIds) => ({
            ...prevIds,
            [currentLabel]: textValues[currentPage] || '',
          }));
          break;
        case "checkbox":
          setUniqueQuestionId((prevIds) => ({
            ...prevIds,
            [currentLabel]: checkboxValues[currentLabel]?.join(", ") || '',
          }));
          break;
        case "address":
          setUniqueQuestionId((prevIds) => ({
            ...prevIds,
            [currentLabel]: getAddressString(),
          }));
          break;
        case "yes_no":
          setUniqueQuestionId((prevIds) => ({
            ...prevIds,
            [currentLabel]: radioValues[currentPage] || '',
          }));
          break;
        case "date":
          setUniqueQuestionId((prevIds) => ({
            ...prevIds,
            [currentLabel]: dateValue,
          }));
          break;
        default:
      }
    }
  }

  useEffect(() => {
    if (dataUpdated) {
      updateUniqueQuestionId();
      setDataUpdated(false);
    }
  }, [checkboxValues, dataUpdated, selectedSelector]);
  
   // Function to check if the input entered is valid
   const isInputValid = () => {
    const currentValue = getCurrentInputValue();
    const isValid = isRequired ? (currentValue && currentValue.trim() !== '') : true;
    
    return isValid;
  };

  //next button function
  const nextButton = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isRequired && !isInputValid()) {

      var requiredAlert = document.querySelector('.required_field');

      if (requiredAlert) {
        requiredAlert.classList.add('show');

        setTimeout(() => {
          requiredAlert?.classList.remove('show');
        }, 3000);
      }

      return;
    }

    //updates both states to move to the next page and increase the progress in the progress bar
      setCurrentPage((currPage) => currPage + 1);
      if(progress < 100) {
        setProgress((currProgress) => currProgress + increment);
      } else {
        return;
      }

      updateUniqueQuestionId();  
  };

  // Function to get the current input value based on the form field type
  const getCurrentInputValue = () => {
    switch (formFieldType?.type) {
      case 'selectbox':
      case 'salutation':
        return selectedSelector[currentPage] || '';
      case 'firstname':
      case 'surname':
      case 'email':
      case 'phone':
      case 'number':
      case 'textarea':
        return textValues[currentPage] || '';
      case 'checkbox':
        return checkboxValues[label]?.join(', ') || '';
      case 'address':
        const addressString = getAddressString();
        const cleanedAddress = addressString.replace(/,/g, '').trim();
        return cleanedAddress === '' ? '' : addressString;
      case 'yes_no':
        return radioValues[currentPage] || '';
      case 'date':
        return dateValue;
      default:
        return '';
    }
  };

  //Previous button funciton
  //Updates both states to decrease in value causing the page to move back by one along with the
  //progress bar losing progress
  const prevButton = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
          setCurrentPage((currPage) => currPage - 1);
          setProgress((currProgress) => currProgress - increment);
  };

  const navigate = useNavigate();

  const handleFormSubmit = async () => {

    if (isRequired && !isInputValid()) {

      var requiredAlert = document.querySelector('.required_field');

      if (requiredAlert) {
        requiredAlert.classList.add('show');

        setTimeout(() => {
          requiredAlert?.classList.remove('show');
        }, 3000);
      }

      return;
    }

    // Filters through each question and collects data used for the Data in postData
       const formData = configData.map((question, index) => {
         const label = question.label;
         switch (question.formFieldType?.type) {
           case "selectbox":
           case "salutation":
             return { key: label, value: selectedSelector[index] || '' };
           case "firstname":
           case "surname":
           case "email":
           case "phone":
           case "number":
           case "textarea":
             return { key: label, value: textValues[index] || '' };
           case "checkbox":
             return { key: label, value: checkboxValues[label]?.join(", ") || '' };
           case "address":
             return { key: label, value: getAddressString() };
           case "yes_no":
             return { key: label, value: radioValues[index] || '' };
           case "date":
             return { key: label, value: dateValue };
           default:
             return null;
         }
       });
       
    const postData = {
      FirstName: textValues.firstName || '',
      LastName: textValues.surname || '',
      Address: getAddressString(),
      TelephoneNumber: textValues.phone || '',
      MobileTelephoneNumber: textValues.number ||  null,
      BranchName: null,
      Message: null,
      Campaign: formId || null,
      Email: textValues.email || '',
      Data: formData || null,
    };

        try {
            const response = await fetch('https://ingest-form-api.integratedinterest.com/basic', {
             method: 'POST',
             headers: {
                 'x-api-key': 'YksiA5h8Sh_y1H-HD4M7b8JeuqAF4_-5SE6zWFrtinw',
                 'Content-Type': 'application/json',
             },
               body: JSON.stringify(postData),
             });

                const modal = document.querySelector('.modal');
                const overlay = document.querySelector('.modal_overlay');
                const closeBtn = document.querySelector('.close_icon');
                const tryAgain = document.querySelector('.try_again_btn');

              if (response.ok) {
                 navigate('/success', { state: { 
                  successMessage,
                  redirectUrl,
                  downloadUrl,
                 }});

                 } else {
                
                  if(modal && overlay) {
                    modal.classList.add('show');
                    overlay.classList.add('show');
                  }

                  const closeModal = function () {
                    modal?.classList.remove('show');
                    overlay?.classList.remove('show');
                  }

                  closeBtn?.addEventListener("click", closeModal);
                  tryAgain?.addEventListener("click", closeModal);

                  console.error('Failed to post data:', response.status, response.statusText);
                 }
             } catch (error) {
               console.error('Error posting data:', error)
            }
  }

   useEffect(() => {
     if(dataUpdated) {
       handleFormSubmit();
       setDataUpdated(false);
     }
   }, [uniqueQuestionId, dataUpdated]);

  const handleDoneClick = async (e: React.FormEvent) => {
    e.preventDefault();

    updateUniqueQuestionId();
    setDataUpdated(true);
  };

     return (
          <div>
             {/* Checks if loading is true and if it is then it returns the PreLoader component and if it isnt true then
            it returns the form */}
            {loading ? (
              <div className="App">
                <h1>Loading...</h1>
                <PreLoader isLoading={loading}/> 
              </div>
            ) : (
              <div>
              <div className="main__container">
              <div className="image__container"><img src={AbsoluteLogo} alt="Absolute-logo"/></div>
              <div className="sub__container">
                <div className="stage__row">
                  <div>
                    <ProgressStage currentStage={currentPage} totalStages={totalStages} />
                  </div>
                  <ProgressBar progress={progress}/>
                </div>
                <form >
                  <div className="info-entry__container">
                    <div className="stage__number"><span id="stage-number">{currentPage + 1}</span></div>
                    <div className="info__entry">
                      <div className="question__container">
                        <div className="info__title" id="question">
                          {label}
                          {isRequired && <span>*</span>}
                        </div>
                        
                      </div>
                      <div className='required_field'>
                        <h1>This Field is Required</h1>
                      </div>
                      <div className="info__dropdown">
                         {}
                          {(() => {
          
                          switch (formFieldType?.type) {
                            case "salutation":
                            case "selectbox":
                              return <Selector
                              options={fieldOptions || []}
                              selectedValue={getSelectedValue(formFieldType.type)}
                              onValueChange={(value) => handleSelectorChange(formFieldType.type, value)}
                              />
                              case "firstname":
                                return <Text
                                  value={textValues.firstName || ''}
                                  onTextChange={(value) => handleTextChange(value, 'firstName')}
                                  autoComplete='given-name'
                                />
                              case "surname":
                                return <Text
                                  value={textValues[currentPage] || ''}
                                  onTextChange={(value) => handleTextChange(value, 'surname')}
                                  autoComplete='family-name'
                                />
                              case "email":
                                return <Email
                                value={textValues[currentPage] || ''}
                                onEmailChange={(value: string) => handleEmailChange(value, 'email')}
                                onEmailValidityChange={(isValid: boolean) => setIsEmailValid(isValid)}
                                />
                              case "phone":
                                return <PhoneNumber
                                title={description}
                                value={textValues[currentPage] || ''}
                                onPhoneNumberChange={(value) => handlePhoneNumberChange(value, 'phone')}
                                autoComplete='tel'
                                />
                              case "number":
                                return <PhoneNumber
                                title={description}
                                value={textValues[currentPage] || ''}
                                onPhoneNumberChange={(value: string) => handlePhoneNumberChange(value, 'number')}
                                />
                              case "checkbox":
                                return <Checkbox
                                options={fieldOptions || []}
                                name={`checkboxGroup${currentPage}`}
                                selectedValues={checkboxValues[label] || []}
                                onValuesChange={(values) => handleCheckboxChange(values, label)}
                                />
                              case "address":
                                return <Address
                                onAddressChange={handleAddressChange}
                                addressValues={addressValues}
                                />
                              case "textarea":
                                return <Text
                                value={textValues[currentPage] || ''}
                                onTextChange={(value: string) =>
                                  setTextValues((prevValues) => ({
                                    ...prevValues,
                                    [currentPage]: value,
                                  }))
                                }
                                />
                              case "yes_no":
                                return <Radio
                                options={fieldOptions || []}
                                selectedValue={radioValues[currentPage] || ''}
                                onValueChange={(value) => setRadioValues((prevValues) => ({...prevValues, [currentPage]: value}))}
                                />
                              case "date":
                              return <Date
                              onDateChange={handleDateChange}
                              value={dateValue}
                              />
                            default:
                              return null;
                          }
                        })()}   
                    </div>
                    </div>
                  </div>
          
                 <div className="button__div">
                  <button
                  className="prev_button" id="prev-button"
                    disabled={currentPage === 0}
                    onClick={prevButton}>
                    Previous
                  </button>
                  <button
                    type="submit"
                    className="next_button"
                    disabled={currentPage === totalStages - 1}
                    onClick={nextButton}>
                    Next
                  </button>
                  <button
                    type="submit"
                    className="done__button"
                    disabled={currentPage !== totalStages - 1}
                    onClick={handleDoneClick}>
                    Done
                  </button>
                 </div>
                </form>
              </div>
              </div>
            <div className="modal_overlay"></div>
              <div className="modal">
                <div className="modal__content">
                  <span className="close_icon"><i className="fa-solid fa-x"></i></span>
                  <div className="modal_error">
                    <span className="warning_icon"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                    <div className="modal_error_message">
                      <h1>Oops!</h1>
                      <p>Something went wrong. Please try again.</p>
                      <button className="try_again_btn">Try again</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
        );
}

export default Form;