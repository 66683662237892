import React from 'react';

interface FormFieldOptions {
  id: number;
  optionValue: string;
  optionLabel: string;
}

interface RadioProps {
    options: FormFieldOptions[];
    selectedValue: string;
    onValueChange: (value: string) => void;
}

const Radio: React.FC<RadioProps> = ({ options, selectedValue, onValueChange }) => {

    return (
        <div>
          {options.map((option) => (
            <label key={option.id} className="radio__label">
              <input
                type="radio"
                name="radio_option"
                value={option.optionLabel}
                checked={option.optionLabel === selectedValue}
                onChange={() => onValueChange(option.optionLabel)}
              />
              <span className="radio_text">{option.optionLabel}</span>
            </label>
            
          ))}
        </div>
      );
};

export default Radio;