import React, { useState, useEffect, ChangeEvent } from 'react';

interface TextProps {
  autoComplete?: string | undefined;
  onTextChange: (value: string) => void;
  value: string;
}

const Text: React.FC<TextProps> = ({ autoComplete, onTextChange, value }) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const updateText = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    onTextChange(newValue);
  };

  return (
    <div className="text-input__container">
      <input
        type="text"
        className="text__input"
        autoComplete={autoComplete}
        value={localValue}
        onChange={updateText}
      />
    </div>
  );
};

export default Text;
